import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=WideList",
        "componentName": "WideList"
      }}>{`<WideList>
  <WideListItem title="Abonnementsnummer" description="1108298" />
  <WideListItem title="Naam abonnee" description="Foo Bar" />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <h5 {...{
      "id": "widelist",
      "style": {
        "position": "relative"
      }
    }}>{`WideList`}</h5>
    <Props identifier="WideList" mdxType="Props" />
    <h5 {...{
      "id": "widelistitem",
      "style": {
        "position": "relative"
      }
    }}>{`WideListItem`}</h5>
    <Props identifier="WideListItem" mdxType="Props" />
    <h2 {...{
      "id": "title",
      "style": {
        "position": "relative"
      }
    }}>{`Title`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Title denotes the upper textual part of the item.`}</p>
    <p>{`If a `}<inlineCode parentName="p">{`string`}</inlineCode>{` is passed in as the property, it will get the default styling. To
customize styling, you can pass in a `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem title="Abonnementsnummer" description="1108298" />
  <WideListItem title="Naam abonnee" description="Foo Bar" />
  <WideListItem
    title={
      <Text
        fontFamily="primary"
        color="blueBase"
        fontWeight="bold"
        size="Heading4"
      >
        Fancy title
      </Text>
    }
    description="Hello world"
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "description",
      "style": {
        "position": "relative"
      }
    }}>{`Description`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Description denotes the lower textual part of the item.`}</p>
    <p>{`If a `}<inlineCode parentName="p">{`string`}</inlineCode>{` is passed in as the property, it will get the default styling. To
customize styling, you can pass in a `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem title="Abonnementsnummer" description="1108298" />
  <WideListItem title="Naam abonnee" description="Foo Bar" />
  <WideListItem
    title="Hello world"
    description={
      <Text
        fontFamily="primary"
        color="blueBase"
        fontWeight="bold"
        size="Heading4"
      >
        Fancy description
      </Text>
    }
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`This adds an action or extra information to your item.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem
    title="Abonnementsnummer"
    description="1108298"
    action={<LinkText>Link Text</LinkText>}
  />
  <WideListItem
    title="Naam abonnee"
    description="Foo Bar"
    action={<Tooltip label="This is a label" />}
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "clickable",
      "style": {
        "position": "relative"
      }
    }}>{`Clickable`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Indicates to the user that the item can be interacted with, e.g. pointing them
to a new page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem
    title="Abonnementsnummer"
    description="1108298"
    clickable
    as="a"
    href="#test"
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "avatar",
      "style": {
        "position": "relative"
      }
    }}>{`Avatar`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Displays an avatar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem
    title="Naam abonnee"
    description="Foo Bar"
    avatar="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200"
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "avatarcircular",
      "style": {
        "position": "relative"
      }
    }}>{`AvatarCircular`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Make the avatar circular`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem
    title="Naam abonnee"
    description="Foo Bar"
    avatar="https://images.unsplash.com/photo-1502378735452-bc7d86632805?w=200"
    avatarCircular={true}
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "iconleft-and-iconright",
      "style": {
        "position": "relative"
      }
    }}>{`IconLeft and IconRight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Add an icon to the WideListItem to clarify the values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem
    title="Queen"
    description="We Will Rock You"
    iconLeft={<Icon as={PodcastFill} color="primaryBase" />}
    iconRight={<Icon as={Add} />}
  />
  <WideListItem
    title="Black Sabbath"
    description="Paranoid"
    iconLeft={PodcastFill}
    iconRight={Add}
  />
  <WideListItem
    title="Metallica"
    description="Seek and Destroy"
    iconLeft={<PodcastFill />}
    iconRight={<Add />}
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "loading",
      "style": {
        "position": "relative"
      }
    }}>{`Loading`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Indicates to the user that the data to be displayed is being loaded.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem loading={true} />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "elevation",
      "style": {
        "position": "relative"
      }
    }}>{`Elevation`}</h2>
    <p><inlineCode parentName="p">{`default: 1`}</inlineCode></p>
    <p>{`Controls the elevation of the `}<inlineCode parentName="p">{`WideList`}</inlineCode>{` container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList mb={6}>
  <WideListItem title="Abonnementsnummer" description="1108298" />
  <WideListItem title="Naam abonnee" description="Foo Bar" />
</WideList>
<WideList elevation={3}>
  <WideListItem title="Abonnementsnummer" description="1108298" />
  <WideListItem title="Naam abonnee" description="Foo Bar" />
</WideList>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      